<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">系统设置</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">鉴定机构设置</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">{{ stu == 'edit' ? '修改' : '新增' }}机构设置</a>
        </span>
      </div>
      <div class="framePage-body addClassSubject">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="flexcc">
              <el-form ref="Form" :model="Form" :rules="rules" label-width="13.125rem">
                <el-form-item label="机构名称：" prop="compId" class="form-item">
                  <el-select size="small" v-model="Form.compId" remote :remote-method="getuserCompanyList" filterable
                    clearable placeholder="请至少输入两个字搜索" style="width:15rem!important;"
                    :disabled="stu == 'edit' || stu == 'account'">
                    <el-option v-for="item in userCompanyList" :key="item.compId" :label="item.compName"
                      :value="item.compId"></el-option>
                  </el-select>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item label="注册完成后是否需要认证：" prop="registerNeedAuth" class="form-item">
                  <el-radio-group v-model="Form.registerNeedAuth" @change="change1">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="认证模式：" prop="registerAuthType" class="form-item" v-if="Form.registerNeedAuth == '1'">
                  <el-radio-group v-model="Form.registerAuthType">
                    <el-radio :label="item.value" v-for="(item, index) in hrRedirectCodeList" :key="index">{{ item.label
                    }}</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item label="报名是否需要认证：" prop="applyNeedAuth" class="form-item">
                  <el-radio-group v-model="Form.applyNeedAuth" @change="change2">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="认证模式：" prop="applyAuthType" class="form-item" v-if="Form.applyNeedAuth == '1'">
                  <!--{{Form.applyAuthType}}-->
                  <el-radio-group v-model="Form.applyAuthType">
                    <el-radio :label="item.value" v-for="(item, index) in hrRedirectCodeList" :key="index">{{ item.label
                    }}</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="视频是否可拖拽：" prop="allowDrag" class="form-item">
                  <el-radio-group v-model="Form.allowDrag">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item label="上传文件大小要求：" class="form-item" required>
                  <el-input-number v-model="Form.fileLimitMin" :step="1" :min="0" :max="Form.fileLimitMax" :precision="0" :controls="false"
                    size="small"></el-input-number>k
                    <span style="width: 3.75rem;text-align: center;">到</span>
                    <el-input-number v-model="Form.fileLimitMax" :step="1" :min="0" :precision="0" :controls="false"
                    size="small"></el-input-number>k 
                    <span style="color:#999;">（如果是0则不限制）</span>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item label="培训是否需要认证：" prop="trainNeedAuth" class="form-item">
                  <el-radio-group v-model="Form.trainNeedAuth" @change="change3">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="认证模式：" prop="trainAuthType" class="form-item" v-if="Form.trainNeedAuth == '1'">
                  <el-radio-group v-model="Form.trainAuthType">
                    <el-radio :label="item.value" v-for="(item, index) in hrRedirectCodeList" :key="index">{{ item.label
                    }}</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item label="外部证书提前：" prop="outCmsWarnDay" class="form-item">
                  <el-input-number v-model="Form.outCmsWarnDay" :step="1" :min="0" :precision="0" :controls="false"
                    size="small"></el-input-number>天预警（默认为90天）
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item label="启用申请表功能：" prop="enableApplyForm" class="form-item">
                  <el-radio-group v-model="Form.enableApplyForm">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item label="培训能否导入学员：" prop="enableImportUser" class="form-item">
                  <el-radio-group v-model="Form.enableImportUser">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="是否开启短信功能：" prop="enableSendSms" class="form-item">
                  <el-radio-group v-model="Form.enableSendSms">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="店铺有效期止：" prop="shopExpiryDate" class="form-item">
                  <el-date-picker size="small" v-model="Form.shopExpiryDate" type="date" format="yyyy/MM/dd"
                    value-format="yyyy/MM/dd" placeholder="请选择店铺有效期止" :picker-options="pickerOptions"
                    style="width: 15rem!important;"></el-date-picker>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item label="店铺启用功能：" prop="functionIds" class="form-item">
                  <el-checkbox-group v-model="Form.functionIds">
                    <el-checkbox :label="item.functionId" v-for="(item, index) in functionList" :disabled="item.affix"
                      :key="index">{{ item.functionName }}</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="H5导航模块：" prop="moduleIds" class="form-item">
                  <el-checkbox-group v-model="Form.moduleIds" @change="changeMoudle">
                    <el-checkbox :label="item.moduleId" v-for="(item, index) in moduleList" :disabled="item.affix"
                      :key="index">{{ item.moduleName }}</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="子模块：" prop="children" class="form-item" v-if="childrenList.length > 0">
                  <el-checkbox-group v-model="Form.children">
                    <el-checkbox :label="item.moduleId" v-for="(item, index) in childrenList" :disabled="item.affix"
                      :key="index">{{ item.moduleName }}</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="准考证模板：" prop="admissionTicketType" class="form-item">
                  <el-checkbox-group v-model="Form.admissionTicketType">
                    <el-checkbox :label="item.templateCode" v-for="(item, index) in admissionCertificateList"
                      :key="index">
                      <el-image style="width: 6.25rem; height: 6.25rem" :src="item.certificateImageShow"
                        :preview-src-list="[item.certificateImageShow]" @click.prevent="justSeeImg">
                      </el-image>
                      <p>{{ item.templateName }}</p>
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="证书模板：" prop="certificateTemplateCode" class="form-item">
                  <el-checkbox-group v-model="Form.certificateTemplateCode">
                    <el-checkbox :label="item.templateCode" v-for="(item, index) in certificateTemplateIdList"
                      :key="index">
                      <el-image style="width: 6.25rem; height: 6.25rem" :src="item.certificateImageShow"
                        :preview-src-list="[item.certificateImageShow]" @click.prevent="justSeeImg">
                      </el-image>
                      <p>{{ item.templateName }}</p>

                    </el-checkbox>
                    <!--                    <el-checkbox :label="item.functionId" v-for="(item,index) in functionList" :disabled="item.affix" :key="index">{{item.functionName}}</el-checkbox>-->
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="短息模板：" prop="warnMsgTemplateId" class="form-item">
                  <el-radio-group v-model="Form.warnMsgTemplateId">
                    <el-radio v-for="(item, index) in templateList" :key="index" :label="item.templateId">{{
                      item.templateContent }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </div>
            <div class="bottom-btn">
              <el-button @click="cancle" class="bgc-bv">取消</el-button>
              <el-button @click="seveData('Form')" class="bgc-bv">保存</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadPic from "@/mixins/UploadPic";
import DatePicker from "@/mixins/DatePicker";
// import tree from "@/components/treePopup";
import { mapGetters } from "vuex";
export default {
  name: "operate/appraisalOrganSetupUpdate",
  components: {
    // tree
  },
  mixins: [UploadPic, DatePicker],
  data() {
    return {
      // 列表页传过来的id
      stu: '',
      configId: '',
      enabled: '',
      // 页面数据
      Form: {
        compId: "",
        registerNeedAuth: "0",
        registerAuthType: "",
        applyNeedAuth: "1",
        applyAuthType: "20",
        allowDrag: "0",
        fileLimitMin: '0',
        fileLimitMax: '0',
        outCmsWarnDay: "90",
        enableApplyForm: '0',
        trainNeedAuth: "0",
        trainAuthType: "",
        enableImportUser: "0",
        enableSendSms: "0",
        shopExpiryDate: "",
        admissionTicketType: [],
        certificateTemplateCode: [],
        functionIds: [],
        moduleIds: [],
        children: [],
        warnMsgTemplateId:"",
      },
      userCompanyList: [],
      certificateTemplateIdList: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < new Date().getTime() - (24 * 60 * 60 * 1000)
        }
      },
      // 数据校验
      rules: {
        compId: [
          { required: true, message: "请选择机构名称", trigger: "change" }
        ],
        registerNeedAuth: [
          { required: true, message: "请选择注册完成后是否需要认证", trigger: "change" }
        ],
        registerAuthType: [
          { required: true, message: "请选择注册完成后是否需要认证-认证模式", trigger: "change" }
        ],
        applyNeedAuth: [
          { required: true, message: "请选择报名是否需要认证", trigger: "change" }
        ],
        applyAuthType: [
          { required: true, message: "请选择报名是否需要认证-认证模式", trigger: "change" }
        ],
        allowDrag: [
          { required: true, message: "请选择视频是否可拖拽", trigger: "change" }
        ],
        outCmsWarnDay: [
          { required: true, message: "请输入外部证书提前预警天数", trigger: "blur" }
        ],
        enableApplyForm: [
          { required: true, message: "请选择培训是否需要认证", trigger: "change" }
        ],
        fileLimitMin: [
          { required: true, message: "请输入上传文件最小", trigger: "blur" }
        ],
        fileLimitMax: [
          { required: true, message: "请输入上传文件最大", trigger: "blur" }
        ],
        trainNeedAuth: [
          { required: true, message: "请选择培训是否需要认证", trigger: "change" }
        ],
        trainAuthType: [
          { required: true, message: "请选择培训是否需要认证-认证模式", trigger: "change" }
        ],
        enableImportUser: [
          { required: true, message: "请选择培训能否导入学员", trigger: "change" }
        ],
        enableSendSms: [
          { required: true, message: "请选择是否开启短信功能", trigger: "change" }
        ],
        admissionTicketType: [
          { required: true, message: "请选择准考证模板", trigger: "change" }
        ],
        certificateTemplateCode: [
          { required: true, message: "请选择证书模板", trigger: "change" }
        ],
        shopExpiryDate: [
          { required: true, message: "请选择店铺有效期止", trigger: "change" }
        ],
        functionIds: [
          { required: true, message: "请选择店铺启用功能", trigger: "change" }
        ],
        moduleIds: [
          { required: true, message: "请选择H5导航模块", trigger: "change" }
        ],
        children: [
          { required: true, message: "请选择H5导航模块-子模块", trigger: "change" }
        ],
        // 行政区划 - 培训类型
        trainTypeId: [
          { required: true, message: "请选择培训类型", trigger: "change" }
        ],
        warnMsgTemplateId: [
          { required: true, message: "请选短息模板", trigger: "change" }
        ]
      },

      admissionTicketTypeList: [],//准考证模板
      hrRedirectCodeList: [],//认证模式
      functionList: [],//功能列表
      moduleList: [],//模块列表
      childrenList: [],//子模块集合
      templateList: [], // 短息模板id
      admissionCertificateList: [], // 准考证模板
    };
  },
  created() {
    this.stu = this.$route.query.stu
    if (this.$route.query.stu == 'edit') {
      this.configId = this.$route.query.configId;
      this.enabled = this.$route.query.enabled;
    }

    this.getCode()
    this.getAdmissionTicketTypeList()
    this.init()
  },
  methods: {
    change1(val) {
      if (val == '1') {
        this.Form.registerAuthType = '20'
      } else {
        this.Form.registerAuthType = ''
      }

    },
    change2(val) {
      if (val == '1') {
        this.Form.applyAuthType = '20'
      } else {
        this.Form.applyAuthType = ''
      }
    },
    change3(val) {
      if (val == '1') {
        this.Form.trainAuthType = '20'
      } else {
        this.Form.trainAuthType = ''
      }
    },
    // 仅看图不触发选中
    justSeeImg() {
      return
    },
    async init() {
      await this.selectFunctionList()
      await this.selectModuleList()
      await this.getCertificateTemplateIdList()
      await this.getAdmissionCertificateList()
      await this.getTemplateList()

      if (this.stu == 'edit') {
        await this.getDetail()
      }
      if (this.stu == 'account') {
        this.userCompanyList = [{
          compId: this.$route.query.compId,
          compName: this.$route.query.compName
        }]
        this.Form.compId = this.$route.query.compId
      }
    },
    // H5导航模块选中
    changeMoudle(val) {
      let _this = this;
      // 题库 和 题库2.0互斥
      if (val.includes('3') && val.includes('8')) {
        for (let i = 0; i < val.length; i++) {
          if (val[i] == 3) {
            val.splice(i, 1)
            break;
          }
          if (val[i] == 8) {
            val.splice(i, 1)
            break;
          }
        }
      }
      _this.childrenList = []
      _this.Form.children = []
      _this.moduleList.forEach((element, index) => {
        val.forEach((e, i) => {
          if (element.moduleId == e) {
            element.children.forEach((eInner, iInner) => {
              _this.childrenList.push(eInner)
              _this.childrenList.forEach((eChild, iChild) => {
                if (eChild.defaultCheck) {
                  _this.Form.children.push(eChild.moduleId)
                }
              })
            })
          }
        })
      })
    },
    // 获取码值
    getCode() {
      // 认证模式
      const hrRedirectCodeList = this.$setDictionary(
        "HR_REDIRECT_CODE",
        "list"
      );

      const hrRedirectCode = [];

      for (const key in hrRedirectCodeList) {
        hrRedirectCode.push({
          value: key,
          label: hrRedirectCodeList[key],
        });
      }
      // this.$nextTick(()=>{
      this.hrRedirectCodeList = hrRedirectCode

    },
    getAdmissionTicketTypeList() {
      // 准考证模板
      const dictionaryList = this.$setDictionary(
        "CT_ADMISSION_TICKET_TYPE",
        "list"
      );

      const admissionTicketTypes = [];

      for (const key in dictionaryList) {
        admissionTicketTypes.push({
          value: key,
          label: dictionaryList[key],
        });
      }
      // this.$nextTick(()=>{
      this.admissionTicketTypeList = admissionTicketTypes
    },
    // 获取功能列表
    async selectFunctionList() {
      let _this = this
      return this.$post("/biz/ct/company/selectFunctionList", {})
        .then((res) => {
          if (res.status == 0) {
            _this.functionList = []
            _this.functionList = res.data

            _this.functionList.forEach((e, i) => {
              if (e.defaultCheck) {
                _this.Form.functionIds.push(e.functionId)
              }
            })
          }
        })
        .catch(() => {
          return;
        });

    },
    // 获取模块列表
    async selectModuleList() {
      let _this = this
      return this.$post("/biz/ct/company/selectModuleList", {})
        .then((res) => {
          if (res.status == 0) {
            _this.moduleList = []
            _this.moduleList = res.data
            let ids = []
            _this.moduleList.forEach((e, i) => {
              if (e.defaultCheck) {
                _this.Form.moduleIds.push(e.moduleId)
                ids.push(e.moduleId)
              }
            })
            _this.changeMoudle(ids)
          }
        })
        .catch(() => {
          return;
        });
    },
    // 获取证书模板列表
    async getCertificateTemplateIdList() {
      let _this = this
      return this.$post("/biz/cms/template/list", {}, 3000, true, 6)
        .then((res) => {
          console.log(res);
          if (res.status == 0) {
            _this.certificateTemplateIdList = []
            _this.certificateTemplateIdList = res.data
            if (_this.stu != 'edit') {
              _this.Form.certificateTemplateCode = []
              res.data.forEach((e, i) => {
                _this.Form.certificateTemplateCode.push(e.templateCode)
              })
            }
          }
        })
        .catch(() => {
          return;
        });
    },
    // 获取准考证模板
    async getAdmissionCertificateList() {
      console.log(1);
      return this.$post("/biz/ct/company/selectCertList", {})
        .then((res) => {
          console.log(res);
          this.admissionCertificateList = res.data
          if (this.stu != 'edit') {
            this.Form.admissionTicketType = []
            res.data.forEach((e, i) => {
              this.Form.admissionTicketType.push(e.templateCode)
            })
          }
        })
        .catch(() => {
          return;
        });
    },
    // 获取短息模板列表
    async getTemplateList() {
      let _this = this
      return this.$post("/biz/ct/company/warningMsgTemplateList")
        .then((res) => {
          this.templateList = res?.data ?? [];
          if(this.templateList.length){
            this.Form.warnMsgTemplateId = res.data[0].templateId;
          }
        })
        .catch(() => {
          return;
        });
    },
    // 编辑时获取详情
    async getDetail() {
      return this.$post("/biz/ct/company/getConfigInfo", { configId: this.configId })
        .then((res) => {
          if (res.status == 0) {
            this.userCompanyList = [{
              compId: res.data.compId,
              compName: res.data.compName
            }]
            this.Form.compId = res.data.compId
            this.Form.registerNeedAuth = res.data.registerNeedAuth ? '1' : '0'
            if (res.data.registerNeedAuth) {
              this.Form.registerAuthType = res.data.registerAuthType
            }
            this.Form.applyNeedAuth = res.data.applyNeedAuth ? '1' : '0'
            if (res.data.applyNeedAuth) {
              this.Form.applyAuthType = res.data.applyAuthType
            }
            this.Form.fileLimitMin = res.data.fileLimitMin ? res.data.fileLimitMin : '0'
            this.Form.fileLimitMax = res.data.fileLimitMax ? res.data.fileLimitMax : '0'
            this.Form.trainNeedAuth = res.data.trainNeedAuth ? '1' : '0'
            this.Form.allowDrag = res.data.allowDrag ? '1' : '0'
            this.Form.outCmsWarnDay = res.data.outCmsWarnDay;
            this.Form.enableApplyForm = res.data.enableApplyForm ? '1' : '0'
            if (res.data.trainNeedAuth) {
              this.Form.trainAuthType = res.data.trainAuthType
            }
            this.Form.enableImportUser = res.data.enableImportUser ? '1' : '0'
            this.Form.enableSendSms = res.data.enableSendSms ? '1' : '0'
            this.Form.admissionTicketType = res.data.admissionTicketType.split(',')
            if (res.data.certificateTemplateCode && res.data.certificateTemplateCode.length > 0) {
              this.Form.certificateTemplateCode = res.data.certificateTemplateCode.split(',')
            }

            this.Form.shopExpiryDate = res.data.shopExpiryDate
            this.functionIds = []

            this.Form.functionIds = []
            res.data.functions.forEach((e, i) => {
              this.Form.functionIds.push(e.functionId)
            })
            this.Form.moduleIds = []
            this.Form.children = []
            res.data.modules.forEach((e, i) => {
              this.Form.moduleIds.push(e.moduleId)
              if (e.children.length > 0) {
                e.children.forEach((eInner, iInner) => {
                  this.Form.children.push(eInner.moduleId)
                })
              }
            })
            if (this.Form.children.length == 0) {
              this.childrenList = []
            }
            if(res.data.warnMsgTemplateId){
              this.Form.warnMsgTemplateId = res.data.warnMsgTemplateId;
            }
            // this.changeMoudle(_this.Form.moduleIds)

            this.enabled = res.data.enabled
          }
        })
        .catch(() => {
          return;
        });
    },
    /* 机构名称 */
    getuserCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.userCompanyList = res.data;
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.userCompanyList = [];
      }
    },
    // 获取培训类型
    getTraintype() {
      this.$post("/sys/category/train-type/tree").then(res => {
        if (res.status == "0") {
          this.trainTypeList = res.data;
        }
      });
    },
    seveData() {
      const el = this.$refs.Form;
      el.validate((valid) => {
        if (valid) {
          this.doAjax();
        }
      });
    },
    // 点击确定 - 有id编辑/无id添加
    doAjax() {
      let params = {
        compId: this.Form.compId,
        registerNeedAuth: this.Form.registerNeedAuth == '1' ? true : false,
        applyNeedAuth: this.Form.applyNeedAuth == '1' ? true : false,
        outCmsWarnDay: this.Form.outCmsWarnDay,
        enableApplyForm: this.Form.enableApplyForm == '1' ? true : false,
        trainNeedAuth: this.Form.trainNeedAuth == '1' ? true : false,
        fileLimitMin: this.Form.fileLimitMin,
        fileLimitMax: this.Form.fileLimitMax,
        enableImportUser: this.Form.enableImportUser == '1' ? true : false,
        enableSendSms: this.Form.enableSendSms == '1' ? true : false,
        allowDrag: this.Form.allowDrag == '1' ? true : false,
        admissionTicketType: this.Form.admissionTicketType.join(','),
        certificateTemplateCode: this.Form.certificateTemplateCode.toString(),
        shopExpiryDate: this.Form.shopExpiryDate,
        warnMsgTemplateId: this.Form.warnMsgTemplateId,
      };
      let setFunctionIds = new Set(this.Form.functionIds);
      params.functionIds = [...setFunctionIds].sort();

      let setModuleIds = new Set([...this.Form.moduleIds, ...this.Form.children]);
      params.moduleIds = [...setModuleIds].sort();

      if (this.Form.registerNeedAuth == '1') {
        params.registerAuthType = this.Form.registerAuthType
      }
      if (this.Form.applyNeedAuth == '1') {
        params.applyAuthType = this.Form.applyAuthType
      }
      if (this.Form.trainNeedAuth == '1') {
        params.trainAuthType = this.Form.trainAuthType
      }
      if (this.stu == 'edit') {
        params.configId = this.configId
        params.enabled = this.enabled

      } else {
        params.enabled = true
      }

      console.log(params)
      this.$post("/biz/ct/company/saveOrUpdate", params)
        .then((res) => {
          if (res.status == 0) {
            this.$message({
              type: "success",
              message: res.message,
            });
            setTimeout(() => {
              this.$router.push({
                path: "/web/operate/appraisalOrganSetupList",
                query: {
                  refresh: 'true',
                },
              });
            }, 300);
          }
        })
        .catch(() => {
          return;
        });
    },
    // 返回
    cancle() {
      this.$router.back();

    },
  }
};
</script>
<style lang="less" scoped>
.addClassSubject {
  .el-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .form-item {
      width: 100%;
      padding-left: 160px;
    }

    .padd0 {
      padding-left: 0;
    }
  }

  .bottom-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .el-textarea {
    .el-textarea__inner {
      resize: none;
    }
  }

  .paraCompleteConditionInput {
    display: inline-block;

    .el-input {
      display: inline-block;
      width: 40% !important;
      margin: 0 .625rem;
      height: 2rem;
    }
  }
}

/deep/.el-input--small .el-input__icon {
  position: relative;
  top: -0.1875rem;
}
</style>
